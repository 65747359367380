import { ref } from 'vue';
import moment from 'moment';
import { ElMessage } from 'element-plus';
export default {
  name: 'CommonHeader',
  props: {
    showChartType: {
      type: String,
      defaule: 'singleChart'
    }
  },
  setup: function setup(props, ctx) {
    var dateValue = ref('');

    var changeChartType = function changeChartType() {
      // 改变图表显示类型
      ctx.emit('changeChartType');
    };

    var changeDate = function changeDate(value) {
      // 改变时间范围
      var startTime = value[0];
      var endTime = value[1];
      var diff = moment().isBefore(endTime);
      var isSame = moment(startTime).isSame(moment(endTime), 'year');

      if (diff) {
        ElMessage('结束日期不能大于当前日期');
        return false;
      }

      if (!isSame) {
        ElMessage('不支持跨年查看数据');
        return false;
      }

      ctx.emit('changeDate', value);
    };

    return {
      dateValue: dateValue,
      changeChartType: changeChartType,
      changeDate: changeDate
    };
  }
};