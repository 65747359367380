export default function setOptions (legendData, timeList, seriesData) {
  return {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: legendData,
      right: 100
    },
    grid: {
      left: '3%',
      right: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        restore: {},
        saveAsImage: {}
      },
      right: 20
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: timeList
    },
    yAxis: {
      type: 'value'
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        start: 0,
        end: 100
      }
    ],
    series: seriesData
    // {
    //   name: 'Union Ads',
    //   type: 'line',
    //   data: [220, 182, 191, 234, 290, 330, 310],
    //   areaStyle: {}
    // }
  }
}
