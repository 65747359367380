import { inject, ref, onMounted } from 'vue';
import CommonHeader from './CommonHeader';
import SingleChart from './SingleChart';
import OverallChart from './OverallChart';
import { useRoute } from 'vue-router';
export default {
  name: 'DeviceLineChart',
  components: {
    CommonHeader: CommonHeader,
    SingleChart: SingleChart,
    OverallChart: OverallChart
  },
  setup: function setup(props, ctx) {
    var route = useRoute();
    var isLoading = ref(true);
    var echartsListData = ref(null);
    var overallChartDom = ref(null);
    var showChartType = ref('singleChart');
    var $api = inject('$api');

    var changeChartType = function changeChartType() {
      showChartType.value = showChartType.value === 'singleChart' ? 'overallChart' : 'singleChart';
      ctx.emit('changeDate', {
        start_time: '',
        end_time: ''
      });
    };

    var changeDate = function changeDate(date) {
      var params = {
        start_time: date[0],
        end_time: date[1]
      };
      overallChartDom.value.isLoading = true;
      overallChartDom.value.timeList = [];
      overallChartDom.value.getOverallChart(params);
      ctx.emit('changeDate', params);
    };

    var getSingleChart = function getSingleChart() {
      $api.getSingleChart({
        id: route.query.id
      }).then(function (data) {
        // 获取设备详情单线图
        echartsListData.value = data;
        isLoading.value = false;
      });
    };

    onMounted(function () {
      getSingleChart();
    });
    return {
      echartsListData: echartsListData,
      showChartType: showChartType,
      changeChartType: changeChartType,
      isLoading: isLoading,
      overallChartDom: overallChartDom,
      changeDate: changeDate
    };
  }
};