import _objectSpread from "C:/Users/Administrator/Desktop/shujukeshihuaqianduan/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { ref, onMounted, inject, nextTick } from 'vue';
import * as echarts from 'echarts';
import { useRoute } from 'vue-router';
import setOptions from './setOverallChart';
export default {
  name: 'OverallChart',
  setup: function setup() {
    var route = useRoute();
    var chartDom = ref(null);
    var $api = inject('$api');
    var isLoading = ref(true);
    var timeList = ref([]);

    var getOverallChart = function getOverallChart(params) {
      // 获取设备详情总折线图
      $api.getOverallChart(_objectSpread({
        id: route.query.id
      }, params)).then(function (data) {
        timeList.value = data.time;

        if (!timeList.value.length) {
          isLoading.value = false;
          return;
        }

        nextTick(function () {
          //  DOM 更新后再绘制图表
          drawChart(data);
        });
      }).catch(function () {
        isLoading.value = false;
        timeList.value = [];
      });
    };

    var drawChart = function drawChart(data) {
      // 绘制图表
      var legendData = data.list.map(function (item) {
        return item.name;
      });
      var seriesData = [];
      data.list.forEach(function (item) {
        var obj = {
          name: item.name,
          type: 'line',
          symbol: 'none',
          data: item.data,
          areaStyle: {}
        };
        seriesData.push(obj);
      });
      var chartInstance = echarts.init(chartDom.value);
      var option = setOptions(legendData, timeList.value, seriesData);
      chartInstance.setOption(option);
      isLoading.value = false;
    };

    onMounted(function () {
      getOverallChart();
    });
    return {
      chartDom: chartDom,
      isLoading: isLoading,
      getOverallChart: getOverallChart,
      timeList: timeList
    };
  }
};