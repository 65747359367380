import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7628b7b5"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "params"
};
var _hoisted_2 = {
  class: "label"
};
var _hoisted_3 = {
  class: "content"
};
var _hoisted_4 = {
  class: "value"
};
var _hoisted_5 = {
  class: "chart"
};
var _hoisted_6 = {
  ref: "chartDom",
  class: "chart_dom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.info.name), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString($props.info.value), 1)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, null, 512)])], 64);
}