import "core-js/modules/es.array.map.js";
import { onMounted, ref } from 'vue';
import * as echarts from 'echarts';
import setOptions from './setOptions';
export default {
  name: 'SingleChart',
  props: {
    info: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  setup: function setup(props) {
    var chartDom = ref(null);

    var drawChart = function drawChart() {
      // 绘制图表
      var chartInstance = echarts.init(chartDom.value);
      var xAxisData = props.info.list.map(function (item) {
        return item.time;
      });
      var seriesData = props.info.list.map(function (item) {
        return item.value;
      });
      var option = setOptions(xAxisData, seriesData);
      chartInstance.setOption(option);
    };

    onMounted(function () {
      drawChart();
    });
    return {
      chartDom: chartDom
    };
  }
};