import _objectSpread from "C:/Users/Administrator/Desktop/shujukeshihuaqianduan/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { inject, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';

var initPage = function initPage($api, id) {
  // 初始化页面
  var baseInfo = ref(null);
  $api.getDeviceInfo({
    id: id
  }).then(function (data) {
    // 获取设备详情
    baseInfo.value = data;
  });
  return {
    baseInfo: baseInfo
  };
};

var exportData = function exportData($api, id, props) {
  // 导出
  var isDownload = ref(false);

  var exportDeviceData = function exportDeviceData() {
    // 导出设备数据
    isDownload.value = true;
    $api.exportDeviceData(_objectSpread({
      id: id
    }, props.exportDate)).then(function (data) {
      ElMessage({
        message: '数据导出成功，正在下载',
        type: 'success'
      });
      window.location.href = data;
      isDownload.value = false;
    });
  };

  return {
    exportDeviceData: exportDeviceData,
    isDownload: isDownload
  };
};

export default {
  name: 'BaseInfo',
  props: {
    exportDate: {
      // 导出设备信息的时间字段
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup(props) {
    var $api = inject('$api');
    var route = useRoute();
    var currentDeviceID = route.query.id;

    var _initPage = initPage($api, currentDeviceID),
        baseInfo = _initPage.baseInfo;

    var _exportData = exportData($api, currentDeviceID, props),
        exportDeviceData = _exportData.exportDeviceData,
        isDownload = _exportData.isDownload;

    return {
      baseInfo: baseInfo,
      exportDeviceData: exportDeviceData,
      isDownload: isDownload
    };
  }
};