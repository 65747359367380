import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eb5eb144"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_BaseInfo = _resolveComponent("BaseInfo");

  var _component_LineChart = _resolveComponent("LineChart");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BaseInfo, {
    exportDate: $setup.exportDate
  }, null, 8, ["exportDate"]), _createVNode(_component_LineChart, {
    onChangeDate: $setup.changeDate
  }, null, 8, ["onChangeDate"])]);
}