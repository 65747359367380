export default function setOptions (xAxisData, seriesData) {
  return {
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: 0,
      right: '5%',
      bottom: 0,
      top: '5%',
      containLabel: true
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   },
    //   right: 5
    // },
    xAxis: {
      type: 'category',
      data: xAxisData
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      scale: true
    },
    series: [
      {
        // name: 'Fake Data',
        type: 'line',
        symbol: 'none',
        // sampling: 'lttb',
        // itemStyle: {
        //   color: 'rgb(255, 70, 131)'
        // },
        areaStyle: {
          color: '#98C4E6'
        },
        data: seriesData
      }
    ]
  }
}
