import BaseInfo from './BaseInfo';
import LineChart from './LineChart';
import { reactive } from 'vue';
export default {
  components: {
    BaseInfo: BaseInfo,
    LineChart: LineChart
  },
  setup: function setup() {
    var exportDate = reactive({
      start_time: '',
      end_time: ''
    });

    var changeDate = function changeDate(info) {
      exportDate.start_time = info.start_time;
      exportDate.end_time = info.end_time;
    };

    return {
      exportDate: exportDate,
      changeDate: changeDate
    };
  }
};